<template>
  <div class="card card-custom card-stretch gutter-b">
    <!--begin::Body-->
    <div class="card-body d-flex flex-column">
      <div class="flex-grow-1 pb-5">
        <!--begin::Info-->
        <v-row>
          <v-col cols="8">
            <h1 style="text-transform: uppercase">{{ pageTitle }}</h1>
            <div class="d-flex align-items-center mb-6">
              <span class="text-muted font-weight-bold font-size-lg flex-grow-1"
                >Dieses Protokoll wird automatisiert an den Berichterstatter, dem
                Kunden, der Wäscherei und optional der Buchhaltung/dem Einkauf gesendet.</span
              >
            </div>
          </v-col>
          <v-col cols="4">
            <v-img src="media/logos/itex-logo.png"></v-img>
          </v-col>
        </v-row>
        <!--end::Info-->

        <div class="vld-parent">
          <loading
            :active.sync="isLoading"
            :can-cancel="true"
            :is-full-page="true"
          ></loading>
        </div>

        <v-app>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-container class="mt-12">
              <v-row>
                <v-col cols="12" class="d-flex justify-end">
                  <div>Protokoll-Id: {{ protokoll.id }}</div>
                </v-col>
                <v-col cols="12" class="d-flex justify-end mb-6">
                  <v-btn
                    color="green"
                    elevation="3"
                    xl-large
                    style="color: white;"
                    @click="logout"
                    >LOGOUT
                  </v-btn>
                </v-col>
              </v-row>
              <!-- KUNDENAUSWAHL -->
              <v-row>
                <v-col class="d-flex" cols="12" sm="12">
                  <span class="font-weight-bold">KUNDENAUSWAHL</span>
                </v-col>
                <v-col class="d-flex" cols="12" sm="12">
                  <v-select
                    class="ma-0 pa-0"
                    :items="customer"
                    item-text="company"
                    label="Bitte wählen Sie einen Kunden"
                    @change="selectCustomer($event)"
                    return-object
                    attach=""
                    :rules="[v => !!v || 'Dies ist ein Pflichtfeld']"
                    required
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="d-flex" cols="12" sm="12">
                  <v-text-field
                    class="ma-0 pa-0"
                    :value="
                      protokoll.customer_id
                        ? protokoll.laundry_office
                        : 'Keine Wäscherei hinterlegt'
                    "
                    :disabled="true"
                    label="Wäscherei (wird automatisch befüllt)"
                    attach=""
                  ></v-text-field>
                </v-col>
              </v-row>
              <!-- EMPFÄNGER -->
              <!--            <v-row>
              <v-col class="d-flex" cols="12" sm="12">
                <span class="font-weight-bold">EMPFÄNGER</span><br />
              </v-col>
              <v-col class="d-flex" cols="12" sm="12">
                <v-text-field
                  class="ma-0 pa-0"
                  v-model="protokoll.additional_receiver"
                  label="Weiterer Empfänger (optional)"
                  hint="Sie haben optional die Möglichkeit zusätzlich einene weiteren Empfänger einzutragen"
                  attach=""
                ></v-text-field>
              </v-col>
            </v-row>-->
              <!-- BESUCHSDATUM -->
              <v-row>
                <v-col class="d-flex" cols="12" sm="12">
                  <span class="font-weight-bold">BESUCHSDATUM</span><br />
                </v-col>
                <v-col class="d-flex" cols="12" sm="12">
                  <v-menu
                    class="ma-0 pa-0"
                    ref="menu1"
                    :close-on-content-click="true"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                    attach=""
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :value="protokoll.visit_date | dateFormatter"
                        label="Besuchsdatum"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="protokoll.visit_date"
                      no-title
                      scrollable
                      locale="de-de"
                    >
                    </v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
              <!-- NÄCHSTER BESUCH -->
              <v-row>
                <v-col class="d-flex" cols="12" sm="12">
                  <span class="font-weight-bold">NÄCHSTER BESUCH</span><br />
                </v-col>
                <v-col class="d-flex" cols="12" sm="12">
                  <v-menu
                    class="ma-0 pa-0"
                    ref="menu"
                    :close-on-content-click="true"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                    locale="de-de"
                    attach=""
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :value="protokoll.next_visit_date | dateFormatterMonth"
                        label="Monat"
                        prepend-icon="mdi-calendar"
                        locale="de-de"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        :rules="[v => !!v || 'Dies ist ein Pflichtfeld']"
                        required
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="protokoll.next_visit_date"
                      type="month"
                      no-title
                      scrollable
                      :first-day-of-week="1"
                      locale="de-de"
                    >
                    </v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
              <!-- 4.	Qualität der Wäsche -->
              <v-row>
                <v-col class="d-flex" cols="12" sm="12">
                  <span class="font-weight-bold text-uppercase"
                    >Qualität der Wäsche</span
                  ><br />
                </v-col>
                <v-col class="d-flex" cols="12" sm="12">
                  <ExpansionItem
                    :accordion_data="this.protokoll.laundry_quality"
                    :protokoll_id="this.protokoll.id"
                    @update="updateQuality"
                  >
                  </ExpansionItem>
                </v-col>
              </v-row>
              <!-- 6.	Wäscherei -->
              <v-row>
                <v-col class="d-flex mt-7" cols="12" sm="12">
                  <span class="font-weight-bold text-uppercase">Wäscherei</span
                  ><br />
                </v-col>
                <v-col class="d-flex" cols="12" sm="12">
                  <ExpansionItem
                    :accordion_data="this.protokoll.laundry"
                    :protokoll_id="this.protokoll.id"
                    @update="updateLaundry"
                  >
                  </ExpansionItem>
                </v-col>
              </v-row>
              <!-- NOTIZEN -->
              <v-row>
                <v-col class="d-flex mt-7" cols="12" sm="12">
                  <span class="font-weight-bold">NOTIZEN</span><br />
                </v-col>
                <v-col class="d-flex" cols="12" sm="12">
                  <v-textarea
                    v-model="protokoll.notice"
                    name="input-7-1"
                    filled
                    label="Hinterlegen Sie optional hier Ihre Notizen"
                  ></v-textarea>
                </v-col>
              </v-row>

              <!-- ABSENDEN BUTTON -->
              <v-row>
                <v-col class="d-flex" cols="12" sm="12">
                  <v-btn
                    class="mt-6"
                    block
                    color="green"
                    elevation="3"
                    large
                    x-large
                    style="color: white;"
                    @click="send"
                    >ABSENDEN
                  </v-btn>
                </v-col>
              </v-row>

              <!--            <v-row >
              <v-col cols="12">
                <v-file-input
                    label="File input"
                    outlined
                    :disabled="true"
                    dense
                    capture="environment"
                    accept="image/*"
                ></v-file-input>
              </v-col>
            </v-row>-->
            </v-container>
          </v-form>
        </v-app>
      </div>
    </div>
  </div>
</template>
<style>
.header-mobile-fixed .wrapper {
  padding-top: 5px;
}

.header-fixed.header-bottom-enabled .wrapper {
  padding-top: 5px;
}

.custom_button.theme--light.v-btn.v-btn--disabled {
  background-color: lightgray !important;
}

.content-wrapper,
.flex-row-fluid {
  padding: 5px;
}

.v-image__image--cover {
  background-size: initial;
}
</style>
<script>
import ApiService from "@/core/services/api.service";
import { getUserProfile, LOGOUT } from "@/core/services/store/auth.module";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import ExpansionItem from "./ExpansionItem";
import Item_Stub from "../../../stub/item_stub";
import Swal from "sweetalert2";
import moment from "moment";
import short from "short-uuid";

export default {
  name: "besuchsprotokoll",
  components: { ExpansionItem, Loading },
  data: function() {
    return {
      valid: true,
      pageTitle: "Besuchsprotokoll",
      loadingvariable: false,
      isLoading: false,
      protokoll: {
        id: short.generate(),
        user_id: null,
        user: null,
        customer: null,
        customer_id: null,
        laundry_id: null,
        receiver: [],
        additional_receiver: null,
        visit_date: new Date(
          Date.now() - new Date().getTimezoneOffset() * 60000
        )
          .toISOString()
          .substr(0, 10),
        next_visit_date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        laundry_quality: [],
        laundry_office: "",
        laundry: [],
        notice: null
      },
      customer: [],
      laundry_quality_cat: ["Wäsche", "Frottee", "Tischwäsche ", "Matten"],
      laundry_cat: ["Anlieferung", "Container", "Fahrer"]
    };
  },
  created() {
    this.documents = [];
    this.loadingvariable = true;

    let currentUser = JSON.parse(getUserProfile());
    this.protokoll.user_id = currentUser.id_user;
    this.protokoll.user = currentUser;

    ApiService.query("protokoll/customer")
      .then(({ data }) => {
        this.loadingvariable = false;
        this.customer = data.customer;
      })
      .catch(({ response }) => {
        if (response.status === 401) {
          this.loadingvariable = false;
          this.$store
            .dispatch(LOGOUT)
            .then(() => this.$router.push({ name: "login" }));
        }
      });

    this.laundry_quality_cat.forEach(categories => {
      let itsb = { ...Item_Stub };
      itsb.name = categories;
      this.protokoll.laundry_quality.push(itsb);
    });

    this.laundry_cat.forEach(laundry => {
      let itsb = { ...Item_Stub };
      itsb.name = laundry;
      itsb.checkbox_legung = null;
      itsb.checkbox_flecken = null;
      itsb.checkbox_defekte = null;
      itsb.checkbox_menge = null;
      itsb.checkbox_anderes = true;
      this.protokoll.laundry.push(itsb);
    });
  },
  filters: {
    dateFormatter: function(value) {
      if (value) return moment(String(value)).format("DD.MM.YYYY");
    },
    dateFormatterMonth: function(value) {
      if (value) return moment(String(value)).lang("de").format("MMMM YYYY");
    }
  },
  methods: {
    selectCustomer(data) {
      this.protokoll.customer_id = data.id_customer;
      this.protokoll.customer = data;
      this.protokoll.laundry_id = data.id_laundry;
      this.protokoll.laundry_office = data.name_laundry;
    },
    updateQuality(data) {
      if (data) this.protokoll.laundry_quality = data.accordion_data;
    },
    updateLaundry(data) {
      if (data) this.protokoll.laundry = data.accordion_data;
    },
    sendTest() {
      this.isLoading = true;

      setTimeout(() => {
        this.isLoading = false;
        // Remove page loader after some time
        Swal.fire({
          title: "Erfolgreich versendet",
          text: "Das Besuchsprotokoll wurde erfolgreich versendet",
          icon: "success",
          showCancelButton: false,
          confirmButtonText: "Ok"
        });
      }, 3000);
    },
    send() {
      if (this.$refs.form.validate()) {
        this.isLoading = true;

        ApiService.post("protokoll/send", this.protokoll)
          .then(data => {
            this.isLoading = false;
            if (data.status === 201) {
              Swal.fire({
                title: "Erfolgreich versendet",
                text: "Der Besuchsbericht wurde erfolgreich versendet",
                icon: "success",
                showCancelButton: false,
                confirmButtonText: "Ok"
              }).then(okay => {
                if (okay) window.location.reload();
              });
            }
          })
          .catch(({ response }) => {
            if (response.status === 401) {
              if (response.status === 401) {
                this.loadingvariable = false;
                this.$store
                  .dispatch(LOGOUT)
                  .then(() => this.$router.push({ name: "login" }));
              }
            } else {
              Swal.fire({
                title: "Fehler",
                text: "Beim Versenden des Berichtes ist ein Fehler aufgetreten",
                icon: "error",
                showCancelButton: false,
                confirmButtonText: "Ok"
              });
            }
          });
      } else {
        Swal.fire({
          title: "Warnung",
          text: "Bitte füllen Sie alle Pflichtfelder aus",
          icon: "warning",
          showCancelButton: false,
          confirmButtonText: "Ok"
        });
      }
    },
    logout() {
      this.$store
        .dispatch(LOGOUT)
        .then(() => this.$router.push({ name: "login" }));
    }
  }
};
</script>
